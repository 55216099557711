import styles from "./app.module.scss";
import { LineItem, RecommendedLineItem, RemanResults } from "ui/src/types";
import { popup, ButtonResult, ButtonType, PopupSize, QuantitySelector, updateCartItem, RemanufacturedChoice, addRemanAndDepositItemsToCart, getAvailabilityDisplay } from "ui";
import cn from 'classnames';
import { Inventories } from "ui/src/components/Inventories/Inventories";
import { useState } from "react";

const isLoggedIn = (window as any).app.preloadState.isLoggedIn;

function App() {
    const translations = (window as any).app.preloadState.translation;
    const recommendedSpareParts: RecommendedLineItem[] = (window as any).app.preloadState["recommendedSpareParts"];

    const [availabilityDisplay, setAvailabilityDisplay] = useState(recommendedSpareParts.map(part => part.inventory?.warehouse?.availabilityDisplay));
    const [isAvailableAtBranchDisplay, setIsAvailableAtBranchDisplay] = useState(recommendedSpareParts.map(part => part.inventory?.branch?.hasEnoughStock));

    const updateItem = async (code: string, quantity: number): Promise<void> => {
        await updateCartItem(code, quantity);
        recommendedSpareParts.find(r => r.code == code)!.remanQuantity = 0;

        const stockAvailability = await getAvailabilityDisplay(code);
        if (stockAvailability.availability) {
            const newAvailability = [...availabilityDisplay];
            newAvailability[recommendedSpareParts.findIndex(part => part.code === code)] = stockAvailability.availability;
            setAvailabilityDisplay(newAvailability);
        }
        const newAvailableAtBranchDisplay = [...isAvailableAtBranchDisplay];
        newAvailableAtBranchDisplay[recommendedSpareParts.findIndex(part => part.code === code)] = stockAvailability.hasEnoughInStock;
        setIsAvailableAtBranchDisplay(newAvailableAtBranchDisplay);
    };

    const showInventoryPopup = (part: LineItem, ev: React.MouseEvent) => {
        ev.preventDefault();
        popup(
            translations["inventoryPopup.stockStatusAtFacility"],
            <Inventories itemCode={part.code} itemDisplayName={part.displayName} />,
            [
                { label: translations["inventoryPopup.cancel"], result: ButtonResult.Cancel, type: ButtonType.Primary }
            ],
            PopupSize.Large
        );
    }

    const showRemanufacturePopUp = async (itemCode: string) => {

        let result: RemanResults[string] = undefined!;

        const changeResult = (results: RemanResults) => {
            result = results[itemCode];
        }

        const remanQuantity = recommendedSpareParts.find(r => r.code == itemCode)!.remanQuantity;

        const override = remanQuantity
        ? {
            [itemCode]: remanQuantity
        } : undefined;

        const popupResult = await popup(
            translations["remanufacturedItemPopUp.selectItemToAddToCart"],
            <RemanufacturedChoice changeResult={changeResult} sparePartIds={[itemCode]} quantityOverride={override} />,
            [
                { label: translations["remanufacturedItemPopUp.remove"], result: ButtonResult.No, type: ButtonType.Link, className: "deleteButton", visible: override != null },
                { label: translations["remanufacturedItemPopUp.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined },
                { label: translations["remanufacturedItemPopUp.ok"], result: ButtonResult.Ok, type: ButtonType.Primary }
            ],
            PopupSize.ExtraLarge,
            "noPaddingsPopUp"
        );
        if (popupResult == ButtonResult.Ok) {
            return result;
        } else if (popupResult == ButtonResult.No) {
            return {
                selectedReman: false,
                sparePartQuantity: 0,
                remanQuantity: 0
            };
        } else {
            return undefined;
        }
    }

    const onAddReman = async (code: string, quantity: number) => {
        await addRemanAndDepositItemsToCart(code, quantity);
        recommendedSpareParts.find(r => r.code == code)!.remanQuantity = quantity;
    }

    return <div className={styles.recommendedPartsWrapper}>
        {recommendedSpareParts.map((item, index) => {
            const isAvailableInWarehouse = item.inventory?.warehouse?.isAvailable ?? false;
            return (
                <div key={index} className={cn("body--s", styles.recommendedPart)}><a href={item.url}>
                    <img src={(item.imageUrl ?? "") + "?height=176"} className={styles.pictureThumbnail} />
                    <div title={item.displayName} className={cn("heading--s m-top--x3", styles.titlePlaceholder, styles.recommendedPartTitle)}>
                        {item.displayName}
                    </div>
                    <div className={cn(styles.secondary, "m-bottom--x2", styles.articleNoPlaceholder)}>
                        <span className={cn("firstLetterCapital")}>
                            {translations["sparePartItem.articleNo"]}:
                            {item.code}
                        </span>
                    </div>
                    <div className={styles.unitAndAmountIndicators }>
                        <span className="firstLetterCapital">
                            {translations["sparePartItem.quantityFrp"]}:
                            {item.inventory?.packageQuantity}
                        </span>
                        {!!item.itemUnitId && <span className="firstLetterCapital">
                            {translations["sparePartItem.unit"]}:
                            {item.itemUnitId}
                        </span>}
                    </div>
                    {
                        isLoggedIn && <div className={cn(styles.pricesPlaceholder, "m-bottom--x3")}>
                            {
                                item.hasDiscount
                                    ? (
                                        <div>
                                            <div className={cn("heading--s")}>
                                                {item.unitDiscountedPrice}
                                            </div>
                                            <div className={cn(styles.secondary, styles.strike)}>
                                                {item.unitBasePrice}
                                            </div>
                                            <div className={cn(styles.secondary)}>
                                                <span className="firstLetterCapital">
                                                    {translations["sparePartItem.discount"]}:
                                                </span>
                                                <span className="m-left--x1">
                                                    -{item.discountPercentage} %
                                                </span>
                                            </div>
                                        </div>
                                    )
                                    : item.unitBasePrice && (
                                        <div className={cn("heading--s", styles.pricesPlaceholder)}>
                                            {item.unitBasePrice}
                                        </div>)
                            }
                        </div>
                    }
                    <div className={styles.availabilityIndicators}>
                        <span
                            className={cn("firstLetterCapital m-right--x2 truckIcon", {
                                [styles.closeTruck]: !isAvailableInWarehouse,
                                [styles.truck]: isAvailableInWarehouse,
                            })}
                        >
                            {isAvailableInWarehouse ? 
                                <span dangerouslySetInnerHTML={{ __html: availabilityDisplay[index] ?? "" }}></span> : translations["inventoryPopup.notInStock"]}
                        </span>
                        {item.inventory?.branch?.branchName ?
                            <span
                                onClick={ev => showInventoryPopup(item, ev)}
                                className={cn(styles.inventoryIndicator, {
                                    [styles.check]: isAvailableAtBranchDisplay[index],
                                    [styles.close]: !isAvailableAtBranchDisplay[index],
                                })}
                            >
                                {item.inventory?.branch?.branchName}
                            </span>
                            :
                            <span
                                onClick={ev => showInventoryPopup(item, ev)}
                                className={cn(styles.inventoryIndicator)}
                            >
                                {translations["inventories.selectBranch"]}
                            </span>
                        }
                    </div>
                    <p className={styles.labels}>
                        {item.labels?.length > 0 &&
                            <span key={item.labels[0].text} className={styles.label}>{item.labels[0].text}</span>
                        }
                    </p>
                </a>
                    <div className={styles.quantitySelectorWrapper}>
                        <QuantitySelector forceMinValue={false}
                            buttonLabelRemanBase={translations["sparePartItem.choose"]}
                            buttonLabel={translations["sparePartItem.buy"]}
                            buttonLabelAdded={translations["sparePartItem.added"]}
                            buttonLabelReman={translations["sparePartItem.boughtReman"]}
                            buttonLabelRemanAdded={translations["sparePartItem.addedReman"]}
                            showLogin={item.showLogin}
                            showNotAuthorized={item.showNotAuthorized}
                            showRequestQuotationByEmail={item.showRequestQuotationByEmail}
                            showContactSupport={item.showContactSupport}
                            showBuyButton={item.showBuyButton}
                            requestQuotationByEmail={item.requestQuotationByEmail}
                            contactSupportLink={item.contactSupportLink}
                            className={styles.buttonClass}
                            step={item.inventory?.quantityStep ?? 1}
                            min={item.inventory?.minimumQuantity ?? 0}
                            max={item.inventory?.maximumQuantity ?? 0}
                            initialValue={item.quantity}
                            hasRemanInCart={!!item.remanQuantity}
                            onRemove={(f: () => void) => updateItem(item.code, 0).then(() => f())}
                            onChange={(quantity: number) => updateItem(item.code, quantity)}
                            onAddReman={onAddReman.bind(null, item.code)}
                            showRemanufacturePopUp={item.hasRemanItem ? showRemanufacturePopUp.bind(undefined, item.code) : undefined}
                            hasRemanAvailable={item.hasRemanItem}
                        ></QuantitySelector>
                    </div>
                </div>
            );
        })}
        </div>
}

export default App;
